input[type="radio"] {
  display: none;
}

.star {
  cursor: pointer;
  width: 20%;
  transition: color 200ms;
  transition: all 0.2s ease-in-out;
}

.star:hover {
  transform: scale(1.2);
}
